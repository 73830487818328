<template>
  <div>

    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_balance_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :placeholder="$t('supplier_balance_report.supplier')"
                             label="name" track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('suppliers_reports.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('suppliers_reports.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <!--              <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                <label for="from_date">{{ $t('suppliers_reports.credit_min') }}</label>-->
              <!--                <input v-model="filters.credit_min" type="number" id="credit_min" class="form-control">-->
              <!--              </div>-->
              <!--              <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                <label for="to_date">{{ $t('suppliers_reports.credit_max') }}</label>-->
              <!--                <input v-model="filters.credit_max" type="number" id="credit_max" class="form-control">-->
              <!--              </div>-->
              <!--              <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                <label for="from_date">{{ $t('suppliers_reports.debit_min') }}</label>-->
              <!--                <input v-model="filters.debit_min" type="number" id="debit_min" class="form-control">-->
              <!--              </div>-->
              <!--              <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                <label for="to_date">{{ $t('suppliers_reports.debit_max') }}</label>-->
              <!--                <input v-model="filters.debit_max" type="number" id="debit_max" class="form-control">-->
              <!--              </div>-->
              <div class="form-group col-md-6 mt-10 mb-2">
                <b-form-checkbox id="all_customers" v-model="filters.hide_zero_values" name="all_customers" :value="1" :unchecked-value="0">
                  {{ $t('suppliers_reports.hide_zero_values') }}
                </b-form-checkbox>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>

              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab active :title="$t('details')">
            <div class="row mb-10">
              <div class="col-12 mt-10" id="summaryTableTitle">
                <!--                <h4 class="text-center">{{ $t('supplier_balance_report.balances_report_group_by_all') }}</h4>-->
                <h4 class="text-center">{{ $t('MENU.suppliers_balance_reports') }}</h4>
                <h5 class="text-center">
                  <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>
                </h5>
                <h5 class="text-center">
                  <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>
                  <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>
                </h5>
              </div>
              <div class="d-flex col-12 justify-content-end p-4">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                  <!--                  <download-excel-->
                  <!--                      class="dropdown-item"-->
                  <!--                      :fetch="getDataList"-->
                  <!--                      :fields="json_fields"-->
                  <!--                      :name="$t('supplier_balance_report.supplier_balance_report')+'.xls'">-->
                  <!--                    <i class="la la-file-excel"></i>{{ $t('excel') }}-->
                  <!--                  </download-excel>-->
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>

                  <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <!--                  <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">-->
                  <!--                    <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
                  <!--                  </button>-->
                  <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                  </button>
                </b-dropdown>

              </div>
            </div>
            <div class="col-12 mt-10">
              <div class="table-responsive">
                <table class="table table-bordered" :id="'summaryTable'">
                  <thead>
                  <tr id="myRow1">
                    <th>{{ $t('supplier_balance_report.supplier_code') }}</th>
                    <th>{{ $t('supplier_balance_report.account_no') }}</th>
                    <th>{{ $t('supplier_balance_report.supplier') }}</th>
                    <th>{{ $t('supplier_balance_report.branch') }}</th>
                    <th>{{ $t('supplier_balance_report.balance_before') }}</th>
                    <th>{{ $t('supplier_balance_report.total_purchase') }}</th>
                    <th>{{ $t('supplier_balance_report.total_refund') }}</th>
                    <th>{{ $t('suppliers_reports.net_purchase') }}</th>
                    <th>{{ $t('supplier_balance_report.total_payments') }}</th>
                    <th>{{ $t('supplier_balance_report.settlements') }}</th>
                    <th>{{ $t('supplier_balance_report.supplier_balance') }}</th>
                  </tr>
                  </thead>
                  <tbody>

                  <template v-for="(_row, _index) in data">
                    <tr>
                      <td colspan="11">{{ _row.label }}</td>
                    </tr>
                    <tr v-for="(row, index) in _row.items" :key="'efewfc'+getRandom()+_index+index">
                      <td>{{ row.code }}</td>
                      <td>{{ row.account_no }}</td>
                      <td>{{ row.name }}</td>
                      <td>{{ row.branch }}</td>
                      <td>{{ row.balance_before }}</td>
                      <td>{{ row.total_purchase }}</td>
                      <td>{{ row.total_refund }}</td>
                      <td>{{ row.net_purchase }}</td>
                      <td>{{ row.total_payments }}</td>
                      <td>{{ row.settlements }}</td>
                      <td>{{ row.balance }}</td>
                    </tr>
                    <tr>
                      <td colspan="5">{{ $t('total') }}</td>
                      <th>{{ _row.total_purchase }}</th>
                      <th>{{ _row.total_refund }}</th>
                      <th>{{ _row.net_purchase }}</th>
                      <th>{{ _row.total_payments }}</th>
                      <th>{{ _row.settlements }}</th>
                      <th>{{ _row.balance }}</th>
                    </tr>
                  </template>

                  </tbody>
                  <tfoot>

                  <tr v-if="dataTotal">
                    <td colspan="7">{{ $t('total') }}</td>
                    <th>{{ dataTotal.sum_net_purchase }}</th>
                    <th>{{ dataTotal.sum_total_payments }}</th>
                    <th>{{ dataTotal.sum_settlements }}</th>
                    <th>{{ dataTotal.sum_balance }}</th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div class="col-12 mt-5 text-center">
              <button class="btn btn-warning" @click="loadMore">
                <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                {{ $t('load_more') }}
              </button>
            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="branch">{{ $t('branch') }}</option>
              </select>

            </li>
          </template>
        </b-tabs>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" :id="'sectionForExport'">
          <thead>
          <tr id="myRow1">
            <th>{{ $t('supplier_balance_report.supplier_code') }}</th>
            <th>{{ $t('supplier_balance_report.account_no') }}</th>
            <th>{{ $t('supplier_balance_report.supplier') }}</th>
            <th>{{ $t('supplier_balance_report.branch') }}</th>
            <th>{{ $t('supplier_balance_report.balance_before') }}</th>
            <th>{{ $t('supplier_balance_report.total_purchase') }}</th>
            <th>{{ $t('supplier_balance_report.total_refund') }}</th>
            <th>{{ $t('suppliers_reports.net_purchase') }}</th>
            <th>{{ $t('supplier_balance_report.total_payments') }}</th>
            <th>{{ $t('supplier_balance_report.settlements') }}</th>
            <th>{{ $t('supplier_balance_report.supplier_balance') }}</th>
          </tr>
          </thead>
          <tbody>

          <template v-for="(_row, _index) in dataDetailsList">
            <tr>
              <td colspan="11">{{ _row.label }}</td>
            </tr>
            <tr v-for="(row, index) in _row.items" :key="'qwerasd'+getRandom()+_index+index">
              <td>{{ row.code }}</td>
              <td>{{ row.account_no }}</td>
              <td>{{ row.name }}</td>
              <td>{{ row.branch }}</td>
              <td>{{ row.balance_before }}</td>
              <td>{{ row.total_purchase }}</td>
              <td>{{ row.total_refund }}</td>
              <td>{{ row.net_purchase }}</td>
              <td>{{ row.total_payments }}</td>
              <td>{{ row.settlements }}</td>
              <td>{{ row.balance }}</td>
            </tr>
            <tr>
              <td colspan="5">{{ $t('total') }}</td>
              <th>{{ _row.total_purchase }}</th>
              <th>{{ _row.total_refund }}</th>
              <th>{{ _row.net_purchase }}</th>
              <th>{{ _row.total_payments }}</th>
              <th>{{ _row.settlements }}</th>
              <th>{{ _row.balance }}</th>
            </tr>
          </template>
          </tbody>
          <tfoot>

          <tr v-if="dataDetailsTotal">
            <td colspan="7">{{ $t('total') }}</td>
            <th>{{ dataDetailsTotal.sum_net_purchase }}</th>
            <th>{{ dataDetailsTotal.sum_total_payments }}</th>
            <th>{{ dataDetailsTotal.sum_settlements }}</th>
            <th>{{ dataDetailsTotal.sum_balance }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SuppliersBalanceReports'"></report-main-header>
      </div>
    </div>

    <div class="selector-export" id="selectorExport"></div>
    <!--end::balance-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-supplier-balances",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier/balance',
      // mainRouteExport:'/reports/supplier-balance-report/data-list',
      mainRouteDependency: 'base/dependency',

      date_time_now: null,
      data: [],
      dataTotal: [],
      dataDetailsList: [],
      dataDetailsTotal: [],
      suppliers: [],
      supplier: null,
      branches: [],
      branch: null,

      filters: {
        supplier_id: null,
        from_date: null,
        to_date: null,

        currency_id: null,
        credit_min: null,
        credit_max: null,
        debit_min: null,
        debit_max: null,
        filter_by: 'supplier',
        hide_zero_values: 0,
        branch_id: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      page: 0,
      currencies: [],
    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.filters.branch_id = val.id;
      } else {
        this.filters.branch_id = null;
      }
    },
    "filters.filter_by": function (val) {
      this.doFilter();
    },
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('supplier_balance_report.code')] = 'code';
      fields[this.$t('supplier_balance_report.account_no')] = 'account_no';
      fields[this.$t('supplier_balance_report.name')] = 'name';
      fields[this.$t('supplier_balance_report.balance_before')] = 'balance_before';
      fields[this.$t('supplier_balance_report.total_purchase')] = 'total_purchase';
      fields[this.$t('supplier_balance_report.total_refund')] = 'total_refund';
      fields[this.$t('suppliers_reports.supplier_balance')] = 'supplier_balance';
      fields[this.$t('supplier_balance_report.net_purchase')] = 'net_purchase';
      fields[this.$t('supplier_balance_report.total_payments')] = 'total_payments';
      fields[this.$t('supplier_balance_report.settlements')] = 'settlements';
      fields[this.$t('supplier_balance_report.balance')] = 'balance';

      return fields;
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.suppliers_balance_reports")}]);
    this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');

  },
  created() {
    this.getCurrencies();
    this.getBranches();
  },
  methods: {
    ...cssStypeForPrintReport,

    convertTableToExcelReport(_tableId) {
      TableToExcel.convert(document.getElementById(_tableId), {
        name: this.$t('MENU.suppliers_balance_reports') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    loadMore() {
      this.page = this.page + 1;
      this.getData();
    },

    doFilter() {
      this.page = 1;
      this.getData();
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    resetFilter() {
      this.filters.supplier_id = null;
      this.supplier = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.credit_min = null;
      this.filters.credit_max = null;
      this.filters.debit_min = null;
      this.filters.debit_max = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;
      this.filters.hide_zero_values = 0;
      this.branch = null;
      this.filters.branch_id = null;
      this.getData();
    },

    dataReport() {
      return this.data;
    },

    getData() {
      // let _filter = this.filters;
      // if (_filter.supplier_id)
      let _limit = (this.page ? this.page : 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.total;
      });
      // else
      //   this.$errorAlertMessage(this.$t('you_should_select_supplier_to_display_the_report'));
    },
    getAllDataForExport(tableId, _export) {
      // let _filter = this.filters;
      // if (_filter.supplier_id)
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: 99999999999999999}}).then((response) => {
        this.dataDetailsList = response.data.data.data;
        this.dataDetailsTotal = response.data.data.total;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });
      // else
      //   this.$errorAlertMessage(this.$t('you_should_select_supplier_to_display_the_report'));

    },
    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if (_currency) {
          this.filters.currency_id = _currency.id;
        }
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getDataList() {
      this.setReportLog('excel', 'suppliers balance reports');
      let _data = [...this.data];
      _data.push({
        code: this.$t('total'),
        account_no: '',
        name: '',
        balance_before: '',
        total_purchase: '',
        total_refund: '',
        supplier_balance: '',
        net_purchase: this.dataTotal.sum_net_purchase,
        total_payments: this.dataTotal.sum_total_payments,
        settlements: this.dataTotal.sum_settlements,
        balance: this.dataTotal.sum_balance,
      });
      return _data;
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    printData(tableId) {
      this.setReportLog('pdf', 'suppliers balance reports');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'suppliers balance reports');
      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('supplier_balance_report.supplier_balance_report'), this.$t('supplier_balance_report.supplier_balance_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>